/*!
 * jQuery Responsive Navigation plugin
 * Author: Globalia
 * Licensed under the WTFPL license
 */


( function( $ ) {

    "use strict";

    var pluginName = "navigation"

    function Plugin ( element ) {
        this.element = element;
        this._name = pluginName;
        this.$overlay = $('<div gr-nav-overlay />');
        this.$handler = $(element).closest('[gr-nav-handler]');
        this.init();
    }

    $.extend( Plugin.prototype, {
        init: function() {
            var instance = this;
            instance.$overlay.appendTo(instance.$handler);
            $('[gr-nav-trigger]', $(instance.element)).on('click', function(event) {
                event.preventDefault();
                instance.toggle();
            });
            instance.$overlay.on('click', function(event) {
                event.preventDefault();
                instance.toggle();
            });
        },
        toggle: function() {
            var instance = this;
            $(instance.element).toggleClass('is-open');
            instance.$overlay.toggleClass('is-visible');
            instance.$handler.toggleClass('is-overlaid');
        },
        close: function() {
            var instance = this;
            $(instance.element).removeClass('is-open');
            instance.$overlay.removeClass('is-visible');
            instance.$handler.removeClass('is-overlaid');
        },
        callFunction: function (call, options) {
            if(typeof this[call] === 'function') {
                return this[call](options);
            }
        }
    } );

    $.fn[ pluginName ] = function(call, options ) {
        return this.each( function() {
            if ( !$.data( this, "plugin_" + pluginName ) ) {
                $.data( this, "plugin_" + pluginName, new Plugin( this, options ) );
            } else {
                return $.data(this, "plugin_" + pluginName).callFunction(call, options);
            }
        } );
    };

} )( require('jquery') );


/*!
 * jQuery Responsive Navigation plugin Addon : Sub-Navigation
 * Author: Globalia
 * Licensed under the WTFPL license
 */


( function( $, window ) {

    "use strict";

    var pluginName = "toggler",
        defaults = {
            breakpoint: 1024
        };

    function Plugin ( element, options ) {
        this.element = element;
        this.settings = $.extend( {}, defaults, options );
        this._defaults = defaults;
        this._name = pluginName;
        this.init();
    }

    // Avoid Plugin.prototype conflicts
    $.extend( Plugin.prototype, {
        init: function() {

            var instance = this;
            var trigger;

            $(instance.element).removeClass('is-active');
            $(instance.element).children('.nav__sub').removeClass('is-open');

            if($(window).width()<this.settings.breakpoint){
                if(!$('i', $(instance.element)).length){
                    $(instance.element).append('<i class="gvp-caret-down"/>');
                }
                else {
                    $('i', $(instance.element)).addClass('gvp-caret-down').removeClass('gvp-caret-up');
                }
                trigger = $('i', $(instance.element));

                trigger.off();

                trigger.on('click',function(event){
                    event.preventDefault();
                    if(trigger.hasClass('gvp-caret-up') || trigger.hasClass('gvp-caret-down')) {
                        trigger.toggleClass('gvp-caret-up gvp-caret-down');
                    }
                    $(instance.element).toggleClass('is-active');
                    instance.toggleTarget();
                });
            } else {
                if($('i', $(instance.element)).length){
                    $('i', $(instance.element)).remove();
                }
            }
        },
        toggleTarget: function() {

            var instance = this;
            var target = $(instance.element).children('.nav__sub');

            if($(instance.element).hasClass('is-active')){
                $(target).addClass('is-open');
                $(target).on('click', function(event){
                    event.stopPropagation();
                });
            } else {
                $(target).removeClass('is-open');
                $(target).off();
            }
        },
        callFunction: function (call, options) {
            if(typeof this[call] === 'function') {
                return this[call](options);
            }
        }
    } );

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[ pluginName ] = function(call, options ) {
        return this.each( function() {
            if ( !$.data( this, "plugin_" + pluginName ) ) {
                $.data( this, "plugin_" + pluginName, new Plugin( this, options ) );
            } else {
                return $.data(this, "plugin_" + pluginName).callFunction(call, options);
            }
        } );
    };

}(require('jquery'), window));

